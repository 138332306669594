
.code-window {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    overflow-x: hidden;
    justify-content: center;
    align-items: center;
    row-gap: 1px;

    & .code-window-bar {
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: end;
        align-items: center;
        column-gap: 15px;
        background-color: #FAFAFA;
        padding-right: 50px;

        & .code-name {
            font-size: 17px;
            font-weight: 500;
            color: #8A8D8E;
        }

        & .code-link {
            font-size: 12px;
            background-color: black;
            width: 25px;
            height: 25px;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;

            & img {
                width: 50%;
            }
        }
    }

    & .code-bar-separator {
        width: 80%;
        height: 1px;
        border-radius: 10px;
        background-color: #8A8D8E;
    }

    & .code {
        position: relative;
        width: 100%;
    }
}
