
.callToAction_button {
    all: unset;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #BBBBB2;
    border-radius: 100px;
    overflow: hidden;
    color: black;
    
    transition: color 0.3s, background-color 0.3s;

    &:hover {
        color: white;
        background-color: #8B8D8E;
        transition: color 0.3s, background-color 0.3s;
        cursor: pointer;
    }

    &:active {
        color: white;
        background-color: #024D4E;
        transition: color 0.3s, background-color 0.3s;
    }
}

.callToAction_button_dark {
    @extend .callToAction_button;

    background-color: black;
    color: white;
}

.callToAction_button_empty {
    @extend .callToAction_button;

    border: #BBBBB2 4px solid;
    background-color: transparent;

    color: #BBBBB2;

    &:hover {
        color: white;
        border-color: white;
        transition: color 0.3s, border-color 0.3s;
    }

    &:active {
        color: #BBBBB2;
        border-color: #BBBBB2;
        transition: color 0.1s, border-color 0.1s;
    }
}

.callToAction_button_empty_dark {
    @extend .callToAction_button_empty;

    background-color: black;
    color: white;
}
