
main {

    & .services {
        height: fit-content;
        padding: 150px 0 100px 0;
        display: flex;
        justify-content: start;
        align-items: center;
        flex-direction: column;
        row-gap: 70px;
        background-color: black;

        & .services_title {
            width: 80%;
            display: flex;
            justify-content: center;
            font-size: 50px;
            font-weight: 600;
            color: white;
            text-align: center;
        }

        & .services_list {
            width: 85%;
            height: 100%;
            background-color: black;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            row-gap: 30px;
            column-gap: 30px;

            & .service {
                width: 200px;
                height: 50px;
                border-radius: 10px;
                border: 7px solid rgba($color: white, $alpha: 1);
                color: rgba($color: white, $alpha: 1);
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;
                transition: color 0.5s, border-color 0.5s;
                padding: 10px 10px 10px 20px;

                & .service_name {
                    flex: 5;
                }

                & .service_image {
                    flex: 1;
                    position: relative;
                    width: 40px;
                    height: 40px;
                    overflow: hidden;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    & img {
                        position: absolute;
                        width: 100%;

                        &:nth-child(2) {
                            opacity: 0;
                            transition: opacity 0.5s;
                        }

                        &:nth-child(3) {
                            opacity: 0;
                            transition: opacity 0.5s;
                        }
                    }
                }

                &:hover {
                    color: rgba($color: #64B3B2, $alpha: 1);
                    border: 7px solid rgba($color: #64B3B2, $alpha: 1);
                    transition: color 0.5s, border-color 0.5s;
                    cursor: pointer;

                    & .service_image {
                        & img {
                            &:nth-child(2) {
                                opacity: 1;
                                transition: opacity 0.5s;
                            }
                        }
                    }
                }
            }

            & .service_selected {
                @extend .service;

                border-color: #64B3B2;
                color: #64B3B2;

                & .service_image {
                    & img {
                        &:nth-child(1) {
                            opacity: 0;
                        }

                        &:nth-child(2) {
                            opacity: 0;
                        }
                        
                        &:nth-child(3) {
                            opacity: 1;
                        }
                    }
                }

                &:hover {
                    border-color: #8B8D8E;
                    color: #8B8D8E;

                    & .service_image {
                        & img {
                            &:nth-child(1) {
                                opacity: 0;
                            }
    
                            &:nth-child(2) {
                                opacity: 0;
                            }
                            
                            &:nth-child(3) {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }

    .service_details {
        height: fit-content;
        padding: 120px 0 80px 0;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        row-gap: 100px;
    }
}
