
.selector {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    border: 2px solid white;
    display: flex;
    flex-direction: row;
    justify-content: end;

    & .selector_selected {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;

        & .selected {
            cursor: pointer;
        }
        
        & .selector_options {
            width: 100%;
            position: absolute;
            background-color: rgba($color: lightgray, $alpha: 1);
            display: flex;
            flex-direction: column;
            justify-content: start;
            align-items: center;
            row-gap: 1px;
            transform: translate(0, 80%);
            border-radius: 10px;
            overflow: hidden;
            z-index: 200;
    
            & .option {
                all: unset;
                width: 100%;
                height: 35px;
                background-color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 15px;
                color: #8B8D8E;
                transition: color 0.5s;

                &:hover {
                    color: black;
                }
            }
        }   
    }

    & .selector_extender {
        all: unset;
        position: relative;
        z-index: 50;
        width: 20%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        & .extender_button {
            width: 15%;
            max-width: 25px;
            aspect-ratio: 1/1;
            background-color: bla;
            display: flex;
            justify-content: center;
            align-items: center;

            & img {
                width: 100%;
                width: 20px;
                position: absolute;

                &:nth-child(2) {
                    opacity: 0;
                    transition: opacity 0.2s;
                }
            }
        }

        & .extender_button_extended {
            @extend .extender_button;

            & img {
                
                &:nth-child(1) {
                    opacity: 0;
                    transition: opacity 0.2s;
                }

                &:nth-child(2) {
                    opacity: 1;
                }
            }

        }
    }
}
