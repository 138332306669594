
.us_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 140px 0 100px 0;
    row-gap: 50px;

    & .us_title {
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        row-gap: 30px;

        & .main_title {
            font-size: 40px;
            font-weight: 600;
        }

        & .sub_title {
            font-size: 25px;
            font-weight: lighter;
        }
    }

    & .content_one {
        all: unset;
        width: 80%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        column-gap: 50px;

        & .left_content {
            flex: 1;
            min-width: 300px;
            padding: 0 40px 0 40px;

            & .info_one {
                text-align: center;

                & p:nth-child(2) {
                    font-size: 17px;
                }
            }
        }

        & .right_content {
            padding-top: 120px;

            & .globe {
                flex: 1;
                text-align: center;
                font-size: 15px;
                display: flex;
                flex-direction: column;
                row-gap: 10px;
                background-color: hsl(204, 78%, 91%);
                padding: 20px;
                border-radius: 20px;
                color: #168583;
                max-width: 250px;
                min-width: 250px;
    
                & .quote {
                    font-weight: lighter;
                    & span {
                        font-size: 20px;
                        font-weight: bold;
                    }
                }
    
                & .quote_person {
                    text-align: right;
                    font-size: 15px;
                    font-style: italic;
                    font-weight: 550;
                }
            }
        }
    }

    & .content_two {
        all: unset;
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & .title {
            padding-top: 50px;
            height: 150px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 30px;
            font-weight: bold;
        }

        & .differenciators {
            width: 100%;
            display: flex;
            flex-direction: row;
            overflow: hidden;
            column-gap: 40px;
            justify-content: center;

            & .value {
                flex: 1;
                max-width: 200px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                padding: 20px;
                color: white;
                border-radius: 20px;

                &:nth-child(1) {
                    background-color: #64B3B2;
                }

                &:nth-child(2) {
                    background-color: #108281;
                }

                & .icon {
                    width: 30%;
                    min-width: 50px;
                    max-width: 80px;
                    
                    & img {
                        width: 100%;
                    }
                }

                &:nth-child(3) {
                    background-color: #B4D9D9;
                }
            }
        }
    }
}

@media (max-width: 700px) {
    .us_section .content_two .differenciators {
        flex-direction: column;
        align-items: center;
        row-gap: 40px;
    }

    .us_section .content_one {
        flex-direction: column;

        & .info_one {
            font-size: 17px;

            & p {
                font-size: 17px;
            }
        }

    }
}

@media (max-width: 800px) {
    .us_section .content_one {
        flex-direction: column;
        row-gap: 40px;
        
        & .right_content {
            padding: 0;
        }

        & .info_one {
            font-size: 17px;

            & p {
                font-size: 17px;
            }
        }
    }
}