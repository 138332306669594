.video_tool {
    position: relative;
    width: 80%;
    height: fit-content;
    background-color: black;
    display: flex;
    justify-content: center;
    border-radius: 20px;
    overflow: hidden;

    & video {
        width: 100%;
    }

    & .video_overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba($color: black, $alpha: 0.4);
        display: flex;
        justify-content: center;
        align-items: center;

        & button {
            all: unset;
            width: 100px;
            height: 100px;
            border-radius: 100%;
            background-color: rgba($color: #000000, $alpha: 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
            transition: background-color 0.5s;

            & img {
                width: 40px;
            }

            &:hover {
                background-color: rgba($color: white, $alpha: 0.5);
                transition: background-color 0.5s;
            }
        }
    }

    & .video_overlay_playing {
        @extend .video_overlay;

        background-color: transparent;
        transition: background-color 0.5s;

        & button {
            opacity: 0.05;
            background-color: rgba($color: white, $alpha: 0.5);
            transition: background-color 0.5s;

            &:hover {
                opacity: 1;
            }
        }
    }
}


@media (max-width: 850px) {
    .video_tool {
        height: 500px;
    }
}
