
.values_box {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px 0 0 0;


    & .stack_animation {
        position: absolute;
        opacity: 0.2;
        z-index: 1;
        transition: opacity 1s;

        &:hover {
            opacity: 0.8;
            transition: opacity 1s;
        }
    
        &:nth-child(1) {
            width: 50%;
            max-width: 500px;
            top: -50px;
            left: 25px;
        }
    
        &:nth-child(2) {
            width: 30%;
            max-width: 300px;
            top: -150px;
            right: 40px;
        }
    
        &:nth-child(3) {
            width: 20%;
            max-width: 200px;
            right: -100px;
            top: 350px;
        }
    
        &:nth-child(4) {
            width: 20%;
            max-width: 200px;
            top: 280px;
            right: 300px;
        }
    
        &:nth-child(5) {
            width: 10%;
            max-width: 100px;
            top: 150px;
            right: 150px;
        }
    
        & img {
            width: 100%;
        }
    }

    & .values_mission_values {
        width: 80%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 0 10px 0;

        & .values_mission {
            display: flex;
            width: 100%;
            height: fit-content;
            flex-direction: column;
            row-gap: 20px;
            justify-content: center;
            text-align: center;
            align-items: center;
    
            & .values_mission_title {
                width: fit-content;
                font-size: 50px;
                font-weight: bold;
                color: black;
                z-index: 2;
            }
    
            & .values_mission_content {
                width: fit-content;
                font-size: 19px;
                font-weight: lighter;
                max-width: 600px;
                color: #024D4E;
                z-index: 2;
                padding: 0 0 100px 0;
            }
        }

        & .values_values {
            width: 80%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            & .values_values_icons {
                width: 80%;
                display: flex;
                flex-direction: row;
                column-gap: 40px;
                row-gap: 40px;
                justify-content: center;
                padding: 50px;

                & .values_value {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    row-gap: 15px;
                    height: 150px;
                    z-index: 3;
    
                    &:nth-child(1) {
                        & .value_value_title {
                            color: #64B3B2;
                        }
                    }
    
                    &:nth-child(2) {
                        & .value_value_title {
                            color: #108281;
                        }
                    }
    
                    &:nth-child(3) {
                        & .value_value_title {
                            color: #056766;
                        }
                    }
        
                    & .value_value_title {
                        font-size: 20px;
                        font-weight: 600;
                        transition: color 0.2s;
                    }
        
                    & .values_value_ball {
                        position: relative;
                        width: 70px;
                        height: 70px;
                        border-radius: 100%;
                        background-color: rgba($color: white, $alpha: 0.5);
                        box-shadow: 0 0 8px rgba($color: black, $alpha: 0.3);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        transition: width 0.5s, height 0.5s;
    
                        & img {
                            position: absolute;
                            width: 35px;

                            &:nth-child(2) {
                                opacity: 0;
                                transition: opacity 0.5s;
                            }
                        }
        
                        &:hover {
                        }
                    }
    
                    &:hover {
                        & .value_value_title {
                            color: black;
                            transition: color 0.5s;
                        }

                        & .values_value_ball {
                            width: 80px;
                            height: 80px;
                            transition: width 0.5s, height 0.5s;

                            & img {
                                &:nth-child(2) {
                                    opacity: 1;
                                    transition: opacity 0.5s;
                                }
                            }
                        }
                    }
        
                    &:hover .value_value_content {
                        display: flex;
                    }
        
                }
            }
        }
    }

    & .values_values_box {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 20px;
    
        & .selected_value {
            position: relative;
            width: 100%;
            height: 150px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: white;
            z-index: 3;

            & .selected_value_bg {
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: #024D4E;
                display: flex;
                justify-content: center;
                align-items: center;

                & .selected_value_icon {
                    display: none;
                    width: 40px;
                    height: 40px;
    
                    & img {
                        width: 100%
                    }
                }
            }

            & .selected_value_items {
                display: flex;
                justify-content: center;
                align-items: center;

                & .value_value_content {
                    width: 70%;
                    text-align: center;
                    font-size: 18px;
                    font-weight: 400;
                    padding: 30px;
                    color: white;
    
                    animation: showValueContent 0.5s linear forwards;

                    & .value_value_content_highlight {
                        // background-color: rgba($color: #B2D9D9, $alpha: 0.3);
                        border-radius: 20px;
                        padding: 0 10px 0 10px;
                        color: black;
                    }
                }
            }

            
        }

        & .selected_value_hidden {
            @extend .selected_value;

            & .selected_value_bg {
                background-color: #8B8D8E;
                opacity: 0.3;
                transition: background-color 0.5s, opacity 0.3s;

                & .selected_value_icon {
                    display: flex;
                }
            }
        }
    }

}


@keyframes showValueContent {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


@media (max-width: 850px) {

    .values_box .values_mission_values {
        flex-direction: column;
        align-items: center;
        padding-top: 100px;
        row-gap: 50px;

        & .values_mission {
            padding: 0;

            & .values_mission_content {
                width: 80%;
            }
        }
    }

    .values_box .values_mission_values .values_values .values_values_icons {
        flex-direction: column;
    }

    .values_box .values_values_box .selected_value {
        display: none;
    }

    .values_box .values_values_box .selected_value_hidden {
        display: none;
    }

    .values_box .values_mission {
        align-items: center;
    }

    .values_box .values_mission .values_mission_content {
        text-align: center;
        font-size: 18px;
    }

    .values_box .values_mission .values_mission_title {
        text-align: center;
        font-size: 35px;
    }
}
