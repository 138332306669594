
main {
    & .video_section {
        position: relative;
        width: 100%;
        height: 560px;
        overflow-y: hidden;
        padding-top: 80px;
        background-color: black;
    }

    & .values_section {
        position: relative;
        width: 100%;
        height: fit-content;
        overflow-y: hidden;
        overflow-x: hidden;
    }

    & .services_section {
        position: relative;
        width: 100%;
        height: fit-content;
    }

    & .contact_section {
        position: relative;
        width: 100%;
        height: fit-content;
    }
}

