
.hamburguer {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    row-gap: 5px;

    & div {
        flex: 1;
        background-color: #BBBBB2;
        border-radius: 3px;
        transition: background-color 0.3s;

        &:nth-child(2) {
            max-width: 70%;
        }

        &:nth-child(3) {
            max-width: 80%;
        }
    }

    &:hover {
        cursor: pointer;
    }

    &:hover div {
        background-color: white;
        transition: background-color 0.3s;
    }

    &:active div {
        background-color: #BBBBB2;
        transition: background-color 0.1s;
    }
}

.hamburguer_dark {
    @extend .hamburguer;

    & div {
        background-color: black;
    }
}
