@font-face {
  font-family: 'SFPro';
  src: local('SFPro'), url(./font/SFPRODISPLAYREGULAR.OTF) format('opentype');
}

body {
  background-color: white;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  height: fit-content;
  font-family: sans-serif;
}

#root {
  width: 100%;
  height: fit-content;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
