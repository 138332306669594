
.content-main {
    background-color: black;
    padding-top: 180px;
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 10px;

    & .content-space {
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 10px;

        & .content-articles {
            width: 80%;
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 10px;
        }

        & .content-search {
            position: relative;
            width: 100%;
            height: 60px;
            background-color: white;
            border-radius: 20px;
            display: flex;
            align-items: center;
            overflow: hidden;

            & input {
                all: unset;
                width: calc(100% - 40px);
                padding: 20px;
                border-radius: 20px;
            }

            & .content-search-image {
                all: unset;
                position: absolute;
                width: 35px;
                height: 35px;
                background-color: black;
                border-radius: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                right: 20px;
                opacity: 0.8;
                transition: opacity 0.3s, background-color 0.3s;

                &:hover {
                    opacity: 1;
                }

                &:active {
                    opacity: 1;
                    background-color: #8A8D8E;
                }

                & img {
                    width: 50%;
                }
            }
        }
    
        & .content-crums {
            width: calc(100% - 20px);
            padding: 0 10px 0 10px;
            height: 20px;
            color: white;
            display: flex;
            flex-direction: row;
            column-gap: 5px;
            font-size: 15px;
            font-weight: lighter;
            align-items: center;

            & .crum {
                &:hover {
                    color: #8A8D8E;
                    cursor: pointer;
                }

                &:active {
                    color: black;
                }
            }
        }

        & .articles {
            position: relative;
            width: 100%;
            padding-top: 40px;
            background-color: black;
            display: flex;
            justify-content: center;
            align-items: center;

            & .articles-loading {
                width: 50px;
            }

            & .article-payload {
                position: relative;
                width: 100%;
            }

            & .articles-list-box {
                display: flex;
                flex-direction: column;
                row-gap: 30px;
                
                & .articles-list {
                    display: flex;
                    flex-direction: column;
                    row-gap: 50px;
    
                    & .article-item {
                        all: unset;
                        display: flex;
                        flex-direction: column;
                        row-gap: 8px;
                        color: white;
    
                        &:hover {
                            color: #8A8D8E;
                            cursor: pointer;
                        }
    
                        & .article-title {
                            font-size: 25px;
                            font-weight: 600;
                        }
    
                        & .article-subtitle {
                            font-size: 20px;
                            font-weight: 550;
                        }
    
                        & .article-description {
                            font-size: 17px;
                            font-weight: 500;
                        }
    
                        & .article-topics {
                            display: flex;
                            flex-direction: row;
                            column-gap: 20px;
                            padding-top: 5px;
    
                            & span {
                                background-color: #8A8D8E;
                                color: white;
                                padding: 5px 10px 5px 10px;
                                border-radius: 20px;
                                font-size: 14px;
    
                                &:hover {
                                    background-color: black;
                                }
                            }
                        }
                    }
                }
    
                & .articles-pagination {
                    display: flex;
                    justify-content: end;
                    column-gap: 20px;

                    & .page-number {
                        all: unset;
                        color: white;
                        font-size: 16px;

                        &:hover {
                            color: #8A8D8E;
                            cursor: pointer;
                        }
                    }

                    & .page-number-selected {
                        @extend .page-number;

                        color: #8A8D8E;
                    }
                }
            }
        }
    }
}