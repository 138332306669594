.menu {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: end;
    column-gap: 25px;

    & button {
        all: unset;
        display: flex;
        flex-direction: row;
        column-gap: 5px;

        & .menu_option {
            color: white;
            font-size: 15px;
            transition: color 0.2s;
        }

        & .menu_option_selected {
            @extend .menu_option;

            color: #8B8D8E;
        }

        &:hover {
            & .menu_option {
                color: #8B8D8E;
                transition: color 0.2s;
            }
            cursor: pointer;
        }
    }
}

.menu_dark {
    @extend .menu;

    & button {
        color: black;

        & .menu_option {
            color: black;
        }

        & .menu_option_selected {
            color: #8B8D8E;
        }
    }
}
