
.text-box {

    & .default {
        font-size: 18px;
        color: white;
    }

    & .title {
        @extend .default;
        font-size: 40px;
        font-weight: 600;
    }

    & .subtitle {
        @extend .default;
        font-size: 30px;
        font-weight: 550;
    }

    & .head-1 {
        @extend .default;
        font-size: 25px;
        font-weight: 600;
    }

    & .head-2 {
        @extend .default;
        font-size: 20px;
        font-weight: 600;
    }

    & .head-3 {
        @extend .default;
        font-size: 20px;
        font-weight: 550;
    }

    & .quote {
        @extend .default;
        font-style: italic;
    }
}