
.price_main {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 180px 0 100px 0;
    flex-direction: column;
    row-gap: 70px;
    background-color: black;
    
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

    & .prices_title {
        width: 80%;
        display: flex;
        justify-content: center;
        font-size: 50px;
        font-weight: 600;
        color: white;
        text-align: center;
    }

    & .prices_info {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 20px;

        & .prices_info_description {
            text-align: center;
            font-size: 20px;
            font-weight: 550;
            color: white;
            transition: color 0.5s;

            &:hover {
                color: #8B8D8E;
            }
        }

        & .prices_info_params {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            row-gap: 10px;
            flex-wrap: wrap;
            column-gap: 10px;

            & .prices_info_param_title {
                color: white;
                font-size: 18px;
                display: flex;
                flex-direction: row;
                column-gap: 5px;
                transition: color 0.5s;

                & img {
                    width: 20px;
                    transition: opacity 0.5s;
                }

                &:hover {
                    color: #8B8D8E;

                    & img {
                        opacity: 0.3;
                    }
                }
            }
        }
    }

    & .prices_interactive {
        width: 80%;
        display: flex;
        flex-direction: row;
        column-gap: 30px;
        max-width: 900px;
        min-width: 350px;

        & .prices_interactive_params {
            position: relative;
            border-radius: 20px;
            border: 10px solid white;
            flex: 2;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            padding: 50px 0 50px 0;
            row-gap: 20px;

            & .param {
                width: 80%;
                color: white;
                display: flex;
                flex-direction: column;
                row-gap: 20px;

                & .question {
                    font-size: 1.7vw;
                    font-weight: 570;
                    text-wrap: nowrap;

                    & .keyword {
                        font-weight: bold;
                        text-transform: lowercase;
                        
                        &:hover {
                            color: #8B8D8E;
                        }
                    }
                }

                & .tool {
                    width: 100%;
                    height: 50px;
                }
            }
        }

        & .prices_interactive_results {
            position: relative;
            border-radius: 20px;
            border: 10px solid white;
            overflow: hidden;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 50px 40px 50px 40px;
            max-width: 200px;

            & span {
                color: white;
                text-align: center;

                &:hover {
                    color: #8B8D8E;
                }
            }

            & .results_title {
                font-size: 15px;
            }

            & .results_value {
                all: unset;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                row-gap: 5px;
                margin-top: 30px;
                width: 100%;

                & span {
                    all: unset;
                    color: white;

                    &:hover {
                        color: #8B8D8E;
                    }

                    &:nth-child(1) {
                        font-size: 3.5vw;
                        font-weight: 550;
                    }
                    &:nth-child(2) {
                        font-size: 2vw;
                        height: 100%;
                        font-weight: 550;
                        display: flex;
                        align-items: end;
                    }
                }
            }

            & .results_information {
                margin-top: 20px;
                font-size: 14px;
            }

            & .results_disclaimer {
                margin-top: 100px;
                font-size: 12px;
            }
        }
    }
}

@media (min-width: 950px) {
    .price_main {
        & .prices_interactive {

            & .prices_interactive_results {
                & .results_value {
                    & span {
                        &:nth-child(1) {
                            font-size: 33px;
                        }

                        &:nth-child(2) {
                            font-size: 20px;
                        }
                    }
                }
            }

            & .prices_interactive_params {
                & .param {
                    & .question {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .price_main {
        & .prices_interactive {
            & .prices_interactive_params {
                & .param {
                    & .question {
                        font-size: 17px;
                        text-wrap: wrap;
                    }
                }
            }
        }
    }
}

@media (max-width: 900px) {
    .price_main {
        & .prices_interactive {
            flex-direction: column;
            row-gap: 20px;

            & .prices_interactive_results {
                flex: 2;
                max-width: 2000px;

                & .results_disclaimer {
                    margin-top: 30px;
                }
            }
        }
    }
}

@media (max-width: 500px) {
    .price_main {
        & .prices_interactive {
            & .prices_interactive_results {
                & .results_value {
                    & span {

                        &:nth-child(1) {
                            font-size: 30px;
                        }

                        &:nth-child(2) {
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }
}
