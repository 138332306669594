
.screen_menu {
    position: fixed;
    width: 100%;
    height: 0;
    z-index: 500;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: rgba($color: lightgray, $alpha: 0.8);
    animation: appear 0.2s linear forwards;


    & .screen_menu_option {
        all: unset;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px;
        font-weight: 600;
        background-color: rgba($color: white, $alpha: 0.8);
        height: 0;
        opacity: 0;
        animation: appear 0.5s linear forwards;
        animation-delay: 0.2s;

        color: #8B8D8E;

        &:hover {
            color: white;
            background-color: rgba($color: #B2D9D9, $alpha: 0.8);
        }
    }
}

@keyframes appear {
    100% {
        opacity: 1;
        height: 100%;
    }
}
