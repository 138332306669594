
.contact_box {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;

    & .contact_message {
        position: relative;
        flex: 1;
        overflow: hidden;

        & .contact_message_content {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            row-gap: 70px;
            padding: 150px 0 100px 0;

            & .contact_message_title {
                font-size: 25px;
                width: 80%;
                height: fit-content;
                text-align: center;
                font-weight: lighter;
                padding: 10px;
                border-radius: 20px;
                border: 5px solid black;

                & span {

                    &:nth-child(1) {
                        color: #64B3B2;
                    }

                    &:nth-child(2) {
                        color: #056766;
                    }
                }
            }

            & .contact_info {
                width: 80%;
                display: flex;
                flex-direction: column;
                text-align: left;
                font-size: 18px;
                font-weight: lighter;
                row-gap: 20px;
                background-color: transparent;

                & div {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    column-gap: 10px;
                    justify-content: space-around;
                    
                    & span {
                        &:nth-child(1) {
                            width: 20%;
                            height: 40px;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            & span {
                                width: 10px;
                                height: 10px;
                                border-radius: 5px;
                                border: 5px solid black;
                                background-color: rgba($color: black, $alpha: 0.5);
                            }
                        }

                        &:nth-child(2) {
                            width: 100%;
                            text-align: right;
                            display: flex;
                            justify-content: end;
                        }
                    }
                }
            }
        }
    }

    & .contact_form {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 100px 0 100px 0;
        row-gap: 30px;
        background-color: rgba($color: #64B3B2, $alpha: 0.8);
        border-radius: 80px 0 0 80px;

        & .contact_form_title {
            width: 70%;
            font-size: 30px;
            text-align: center;
            font-weight: bold;
            color: white;
        }

        & .contact_form_form {
            width: 70%;
            height: 400px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            overflow: hidden;

            /* Chrome, Safari, Edge, Opera */
            & input::-webkit-outer-spin-button,
            & input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            /* Firefox */
            & input[type=number] {
                -moz-appearance: textfield;
            }

            & input {
                all: unset;
                height: 50px;
                border-radius: 10px;
                border: 5px solid rgba($color: white, $alpha: 0.7);
                transition: border 0.5s;
                color: rgba($color: white, $alpha: 0.7);
                padding: 0 0 0 20px;
                margin-bottom: 30px;

                &:hover {
                    border-color: rgba($color: white, $alpha: 1);
                    color: rgba($color: white, $alpha: 1);
                    transition: border 0.5s, color 0.5s;
                }

                &:focus {
                    border-color: rgba($color: white, $alpha: 1);
                    color: rgba($color: white, $alpha: 1);
                    transition: border 0.5s, color 0.5s;
                }

                &::placeholder {
                    color: rgba($color: white, $alpha: 0.5);
                    transition: color 0.5s;
                }
            }

            & textarea {
                @extend input;
                resize: none;
                height: 120px;
                padding-top: 20px;
                margin: 0;
            }
        }

        & .contact_form_submit {
            all: unset;
            width: calc(70% - 10px);
            height: 80px;
            border-radius: 10px;
            border: 5px solid rgba($color: white, $alpha: 0.7);
            background-color: transparent;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: large;
            font-weight: 600;
            transition: color 0.5s, border-color 0.5s;

            &:hover {
                color: black;
                border-color: black;
                transition: color 0.5s, border-color 0.5s;
                cursor: pointer;
            }

            &:active {
                color: white;
                border-color: white;
                transition: color 0.5s, border-color 0.5s;
            }

        }

        & .contact_form_submit_sent {
            @extend .contact_form_submit;

            border-color: transparent;

            &:hover {
                border-color: transparent;
            }

            &:active {
                color: black;
            }

        }
    }
}

@media (max-width: 850px) {
    .contact_box {
        flex-direction: column;

        & .contact_message .contact_message_content {
            height: 650px;
            padding: 80px 0 80px 0;
        }

        & .contact_form {
            border-radius: 0;
        }
    }
}
