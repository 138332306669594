
.image-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;

    & img {
        width: 100%;
    }

    & .image-name {
        font-size: 15px;
        font-weight: lighter;
        font-style: italic;
        color: white;
    }
}