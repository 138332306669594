
.service_what_is {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 50px;

    & .service_what_is_title {
        width: 80%;
        font-weight: bold;
        font-size: 40px;
        color: #024D4E;
        text-align: center;
    }

    & .service_what_is_fragments {
        width: 70%;
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        text-align: justify;

        & span {
            font-size: 17px;
            font-weight: 590;

            & span {
                color: black;

                &:hover {
                    color: #8B8D8E;
                }
            }
        }
    }
}