
.services_box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 50px;
    background-color: black;

    & .services_bg_stack {
        position: absolute;
        width: 80px;
        right: 30px;
        bottom: 30px;
        opacity: 0.3;
        transition: opacity 1s;
        z-index: 1;

        & img {
            width: 100%;
        }

        &:hover {
            opacity: 0.8;
            transition: opacity 1s;
        }
    }
    
    & .services_title {
        width: 90%;
        padding: 120px 0 0 0;
        color: white;
        font-size: large;
        text-align: left;
        font-size: 50px;
        font-weight: bold;
    }

    & .services_list {
        width: 80%;
        padding: 0 0 150px 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        column-gap: 30px;
        row-gap: 30px;
    }

    & .service {
        position: relative;
        width: 220px;
        height: fit-content;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 30px;
        row-gap: 20px;
        border: 5px solid white;
        border-radius: 30px;

        & .service_title {
            width: 100%;
            display: flex;
            justify-content: start;
            text-align: left;
            font-size: 22px;
            font-weight: 600;
            color: white;
        }

        & .service_description {
            width: 100%;
            display: flex;
            justify-content: end;
            text-align: right;
            color: white;
            height: 0;
            opacity: 0;
            transition: opacity 1s, height 1s;
            font-weight: lighter;
        }

        & .service_icon {
            position: relative;
            background-color: transparent;
            width: 60px;
            height: 60px;
            overflow: hidden;

            & img {
                position: absolute;
                width: 100%;

                &:nth-child(2) {
                    opacity: 0;
                    transition: opacity 0.5s;
                }
            }
        }

        &:hover {
            
            & .service_description {
                display: flex;
                opacity: 1;
                height: 110px;
                transition: opacity 1s, height 1s;
            }

            & .service_icon {
                & img {
                    &:nth-child(2) {
                        opacity: 1;
                        transition: opacity 0.5s;
                    }
                }
            }
        }
    }
}


@media (max-width: 500px) {
    .services_box .services_title {
        text-align: center;
    }
}