
.dragger {
    all: unset;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */


    & .bar {
        all: unset;
        position: absolute;
        width: 100%;
        height: 15%;
        background-color: #8B8D8E;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        & .labels {
            position: relative;
            width: 100%;
            height: 200%;
            transform: translate(0, -180%);
            display: flex;
            flex-direction: row;
            justify-content: center;

            & .label {
                flex: 1;
                position: relative;
                background-color: transparent;
                height: 200%;
                display: flex;
                justify-content: center;
                color: #B2D9D9;
                font-size: 2vw;
                align-items: center;
                transition: color 0.2s, transform 0.2s;
            }

            & .label_tooltip {
                position: absolute;
                background-color: #BBBBB2;
                transform: translate(0, -100%);
                border-radius: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 10;

                & .label_tooltip_container {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    height: 50px;
                    max-height: 50px;
                    max-width: 100px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 10px;
                
                    & .label_tooltip_point {
                        position: absolute;
                        transform: translate(0, 50%) rotate(45deg);
                        height: 15px;
                        width: 15px;
                        background-color: #BBBBB2;
                        z-index: 11;
                        bottom: 0;
                    }

                    & .label_tooltip_text {
                        z-index: 12;
                        height: 100%;
                        overflow: hidden;

                        & .label_tooltip_text_value {
                            width: 100%;
                            position: relative;
                            text-align: center;
                            animation: scroll 5s linear infinite;
                        }
                    }

                    @keyframes scroll {
                        0% {
                            transform: translateY(0);
                        }
                        100% {
                            transform: translateY(-70%);
                        }
                    }

                    & .label_tooltip_text::-webkit-scrollbar {
                        display: none;
                    }
                }
            }

            & .label_tooltip_hidden {
                @extend .label_tooltip;
                display: none;
            }

            & .label_selected {
                @extend .label;
                
                color: white;
                transform: translate(0, -5%);
            }
        }

        & .marker {
            all: unset;
            position: absolute;
            width: 8%;
            max-width: 20px;
            aspect-ratio: 1/1;
            background-color: white;
            border-radius: 100%;
            transform: translate(-50%, 0);
        }
    }
}

@media (min-width: 850px) {
    .dragger {
        & .bar {
            & .labels {
                & .label {
                    font-size: 15px;
                }
            }
        }
    }
}

@media (max-width: 500px) {
    .dragger {
        & .bar {
            & .labels {
                & .label {
                    font-size: 15px;
                }
            }
        }
    }
}

