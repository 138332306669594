.header {
    position: fixed;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 150px;
    min-width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    overflow: hidden;

    .header_box {
        height: 100%;
        width: 90%;
        display: flex;
        flex-direction: row;

        & .logo_section {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;

            & img {
                width: 100%;
                max-width: 250px;
                cursor: pointer;
            }
        }
    
        & .menu_section {
            flex: 3;
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 30px;
    
            & .menu_menu {
                flex: 3;
            }
    
            & .menu_callToAction {
                flex: 1;
                max-width: 150px;
                height: 60%;
                max-height: 50px;
                font-size: 15px;
            }

            & .menu_callToAction_icon {
                display: none;
                width: 50px;
                height: 50px;
                justify-content: center;
                align-items: ce;

                & .callToAction_icon {
                    width: 30px;
                }
            }

            & .menu_menu_hamburguer {
                display: none;
                justify-content: end;
                align-items: center;
                
                & .hamburguer_box {
                    width: 40px;
                    height: 30px;
                }
            }
        }
    }
}

.header_after {
    @extend .header;

    animation: light_header 0.2s linear forwards;
}

@keyframes light_header {
    0% {
        background-color: rgba($color: white, $alpha: 0);  
    }
    100% {
        height: 100px;
        background-color: rgba($color: white, $alpha: 0.9);
    }
}

@media (max-width: 850px) {
    .header .header_box .menu_section {
        flex: 2;
        justify-content: right;
    }

    .header .header_box .menu_section .menu_menu {
        display: none;
    }

    .header .header_box .menu_section .menu_menu_hamburguer {
        display: flex;
        height: 100%;
    }
}

@media (max-width: 650px) {
    .header .header_box .menu_section {
        flex: 1;
    }
}

@media (max-width: 500px) {
    .header .header_box .menu_section {
        flex: 1;
        column-gap: 15px;
    }

    .header .header_box .menu_section .menu_callToAction {
        display: none;
    }

    .header .header_box .menu_section .menu_callToAction_icon {
        display: inline;
    }
}
