
.block {
    width: calc(100% - 40px);
    height: auto;
    padding: 20px;
    display: flex;
    justify-content: start;
}

.block_center {
    @extend .block;
    justify-content: center;
}

.block_right {
    @extend .block;
    justify-content: end;
}