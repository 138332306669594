
footer {
    background-color: black;
    color: white;
    overflow: hidden;

    & .footer_menu {
        height: 250px;
        padding: 50px 0 50px 0;
        display: flex;
        flex-direction: row;

        & .footer_menu_logo {
            flex: 1.5;
            display: flex;
            justify-content: center;
            align-items: center;

            & img {
                width: 100%;
                max-width: 80px;
            }
        }
        
        & .footer_menu_link {
            flex: 5;
            display: flex;
            justify-content: space-around;
            align-items: center;
            text-align: center;
            column-gap: 20px;

            & .footer_menu_link_section {
                display: flex;
                flex-direction: column;
                row-gap: 20px;
                height: 50%;
                align-items: center;

                & .footer_menu_link_section_title {
                    font-weight: bold;
                    font-size: 17px;
                    height: 40px;
                }

                & .footer_menu_link_section_links {
                    display: flex;
                    flex-direction: column;
                    row-gap: 10px;
                    align-items: center;
                    max-height: 150px;
                    flex-wrap: wrap;
                    column-gap: 5px;
                    width: 120px;

                    & a {
                        all: unset;
                        font-size: 15px;
                        transition: color 0.3s;
                        cursor: pointer;

                        & .social_image {
                            position: relative;
                            width: 30px;
                            height: 30px;
                            overflow: hidden;
                            display: flex;

                            & img {
                                width: 100%;
                                position: absolute;
                                transition: opacity 0.5s;
                                
                                &:nth-child(2) {
                                    &:hover {
                                        opacity: 0;
                                        transition: opacity 0.5s;
                                    }
                                }
                            }
                        }

                        &:hover {
                            color: #64B3B2;
                            transition: color 0.3s;
                        }
                    }
                }
            }
        }
    }

    & .footer_information {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        & .footer_information_separation {
            width: 95%;
            height: 1px;
            background-color: rgba($color: white, $alpha: 0.5);
        }

        & .footer_information_content {
            width: 100%;
            height: 100px;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            font-size: 15px;

            & .footer_information_content_links {
                display: flex;
                flex-direction: row;
                column-gap: 20px;


                & button {
                    all: unset;
                    transition: color 0.5s;

                    &:hover {
                        color: #64B3B2;
                        transition: color 0.3s;
                    }
                }
            }
        }
    }
}

@media (max-width: 650px) {
    footer .footer_menu {
        flex-direction: column;
    }
}

@media (max-width: 750px) {
    footer .footer_menu {
        height: 300px;
    }
    footer .footer_information .footer_information_content {
        flex-direction: column;
        padding: 20px 0 20px 0;
    }
}
