
.service_deliverables {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & .service_deliverables_title {
        height: 100px;
        width: 80%;
        font-size: 40px;
        font-weight: bold;
        color: #024D4E;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    & .service_deliverables_box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 50px;
        padding-top: 50px;

        & .service_deliverables_deliverables {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            column-gap: 30px;
            row-gap: 30px;
            flex-wrap: wrap;

            & .service_deliverables_deliverable {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 200px;

                & .service_deliverables_deliverable_image {
                    position: relative;
                    width: 50px;
                    height: 50px;
                    justify-content: center;
                    align-items: center;
                    transition: width 0.5s, height 0.5s;

                    & img {
                        position: absolute;
                        width: 100%;
                        transition: opacity 0.5s;
                        
                        &:nth-child(1) {
                            opacity: 1;
                        }

                        &:nth-child(2) {
                            opacity: 0;
                        }
                    }
                }

                & .service_deliverables_deliverable_content {
                    width: fit-content;
                    border-radius: 15px;
                    padding: 15px;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    row-gap: 0;
                    justify-content: center;
                    align-items: center;
                    font-size: 18px;
                    transition: color 0.5s, row-gap 0.5s;
                    color: #8B8D8E;

                    & .deliverable_items {
                        height: 0;
                        border: 0;
                        row-gap: 0;
                        overflow: hidden;
                        transition: height 0.5s;
                        font-size: 15px;

                        & .deliverable_item {
                            color: black;
                            opacity: 0;
                            transition: opacity 0.5s;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;

                            & span {
                                &:nth-child(2) {
                                    width: 200px;
                                    display: none;
                                    padding: 10px;
                                    color: black;
                                    font-weight: lighter;
                                }
                            }

                            &:hover {
                                color: #8B8D8E;
                                cursor: pointer;
                            }
                            
                            &:active {
                                & span {
                                    &:nth-child(2) {
                                        display: flex;
                                        opacity: 1;
                                        height: fit-content;
                                    }
                                }
                            }
                        }
                    }
                }

                &:hover {
                    color: black;
                    row-gap: 20px;
                    width: 250px;

                    & .service_deliverables_deliverable_content {
                        color: black;

                        & .deliverable_items {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            row-gap: 10px;
                            height: fit-content;
                            padding: 20px;
                            border-radius: 20px;
                            transition: box-shadow 0.5s;
    
                            & .deliverable_item {
                                opacity: 1;
                            }
                        }
                    }

                    & .service_deliverables_deliverable_image {
                        width: 60px;
                        height: 60px;
                        opacity: 1;

                        & img {
                            &:nth-child(1) {
                                opacity: 0;
                            }
    
                            &:nth-child(2) {
                                opacity: 1;
                            }
                        }
                    }
                }
                
            }

        }
    }
}
