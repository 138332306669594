
.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.service_phases {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & .service_phases_title {
        height: 100px;
        width: 80%;
        font-size: 40px;
        font-weight: bold;
        color: #024D4E;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    & .service_phases_box {
        @extend .noselect;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 50px;
        padding-top: 50px;

        & .service_phases_phases {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            column-gap: 30px;
            row-gap: 30px;
            flex-wrap: wrap;

            & .service_phases_phase {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 200px;

                & .service_phases_phase_number {
                    width: 40px;
                    height: 40px;
                    font-size: 20px;
                    border-radius: 100%;
                    border: 3px solid rgba($color: #8B8D8E, $alpha: 0.5);
                    color: #8B8D8E;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transition: color 0.5s, font-size 0.5s, border-width 0.5s, border-color 0.5s;
                }

                & .service_phases_phase_content {
                    width: fit-content;
                    border-radius: 15px;
                    padding: 15px;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    row-gap: 0;
                    justify-content: center;
                    align-items: center;
                    font-size: 18px;
                    transition: color 0.5s, row-gap 0.5s;
                    color: #8B8D8E;

                    & .phase_items {
                        height: 0;
                        border: 0;
                        row-gap: 0;
                        overflow: hidden;
                        transition: height 0.5s;
                        font-size: 15px;

                        & .phase_item {
                            color: black;
                            opacity: 0;
                            transition: opacity 0.5s;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;

                            & span {
                                &:nth-child(2) {
                                    width: 200px;
                                    display: none;
                                    padding: 10px;
                                    color: black;
                                    font-weight: lighter;
                                }
                            }

                            &:hover {
                                color: #8B8D8E;
                                cursor: pointer;
                            }
                            
                            &:active {
                                & span {
                                    &:nth-child(2) {
                                        display: flex;
                                        opacity: 1;
                                        height: fit-content;
                                    }
                                }
                            }
                        }
                    }
                }

                &:hover {
                    color: black;
                    row-gap: 20px;
                    width: 250px;

                    & .service_phases_phase_content {
                        color: black;

                        & .phase_items {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            row-gap: 10px;
                            height: fit-content;
                            padding: 20px;
                            border-radius: 20px;
                            transition: box-shadow 0.5s;
    
                            & .phase_item {
                                opacity: 1;
                            }
                        }
                    }

                    & .service_phases_phase_number {
                        font-size: 30px;
                        width: 50px;
                        height: 50px;
                        color: black;
                        border-width: 5px;
                        border-color: black;
                    }
                }
                
            }

        }
    }
}
