
.careers_section {
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 80px 0 0 0;
    background-color: black;

    & .message {
        width: 80%;
        height: 400px;
        background-color: black;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 25px;
        row-gap: 20px;
        text-align: center;

        & .logo {
            width: 100px;
            & img {
                width: 100%;
            }
        }
    }
}