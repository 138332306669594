
.video_box {
    position: relative;
    width: 100%;
    height: 600px;
    overflow: hidden;

    .video {
        position: absolute;
        width: 100%;
        opacity: 0.8;
        min-width: 1000px;
    }

    & .video_overlay {
        position: absolute;
        background-color: rgba($color: #000000, $alpha: 0.5);
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        background-image: linear-gradient(to top, rgba(0,0,0,1) 50px, rgba(0,0,0,0) 200px);
        background-image: linear-gradient(to bottom, rgba(0,0,0,1) 50px, rgba(0,0,0,0) 200px);

        & .overlay_content {
            width: 80%;
            height: 80%;
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: center;
            row-gap: 35px;
            padding-left: 10;

            & .overlay_texts {
                display: flex;
                width: 700px;
                flex-direction: column;
                white-space: wrap;

                & .overlay_slogan {
                    width: fit-content;
                    padding: 0 10px 0 0;
                    color: #B2D9D9;
                    font-size: 27px;
                    text-transform: uppercase;
                }

                & .overlay_message {
                    color: white;
                    font-size: 68px;
                    font-weight: bold;
                    display: flex;
                    flex-direction: column;

                    & span {
                        width: fit-content;
                        // background-color: rgba($color: black, $alpha: 0.5);
                        padding: 0 20px 0 0;
                        text-shadow: 5px 5px 15px rgba($color: black, $alpha: 0.5);
                    }
                }
            }

            & .overlay_buttons {
                display: flex;
                flex-direction: row;
                column-gap: 30px;
            }

            & .overlay_callToAction {
                width: 190px;
                height: 60px;
                font-size: 18px;
            }
        }
    }
}

@media (max-width: 850px) {
    .video_box .video_overlay .overlay_content .overlay_texts .overlay_message {
        font-size: 58px;
    }
    .video_box .video_overlay .overlay_content .overlay_texts .overlay_slogan {
        font-size: 24px;
    }
}

@media (max-width: 750px) {
    .video_box .video_overlay .overlay_content .overlay_texts .overlay_message {
        font-size: 48px;
    }
    .video_box .video_overlay .overlay_content .overlay_texts .overlay_slogan {
        font-size: 20px;
    }
}

@media (max-width: 650px) {

    .video_box .video_overlay .overlay_content .overlay_texts .overlay_slogan {
        align-items: center;
        text-align: center;
        padding: 0;
        font-size: 18px;
    }

    .video_box .video_overlay .overlay_content .overlay_texts {
        align-items: center;
        width: 350px;
    }

    .video_box .video_overlay .overlay_content .overlay_texts .overlay_message {
        font-size: 44px;
        align-items: center;
    }

    .video_box .video_overlay .overlay_content .overlay_texts .overlay_message span {
        text-align: center;
        padding: 0;
    }

    .video_box .video_overlay .overlay_content {
        align-items: center;
    }
}

@media (max-width: 450px) {

    .video_box .video_overlay .overlay_content .overlay_texts .overlay_slogan {
        font-size: 16px;
    }

    .video_box .video_overlay .overlay_content .overlay_texts {
        width: 300px;
    }

    .video_box .video_overlay .overlay_content .overlay_texts .overlay_message {
        font-size: 38px;
    }
}

@media (max-width: 500px) {
    .video_box .video_overlay .overlay_content .overlay_buttons {
        flex-direction: column;
        row-gap: 20px;
    }   
}
